<template>
  <v-text-field
    v-model="phone"
    v-mask="mask"
    v-bind="$attrs"
    :label="placeholder"
    :disabled="disabled"
    :error-messages="phoneErrors"
    @blur="blur"
    @input="input"
  >
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { minLength } from 'vuelidate/lib/validators'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default {
  name: 'PhoneEdit',

  inheritAttrs: false,

  directives: { mask },

  mixins: [validationMixin],

  emits: ['change', 'validation'],

  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: Boolean,
      default: false,
    },
    withNickname: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      phone: '',
      mask: this.value && this.value[0] === '@' ? '@XXXXXXXXXXXXXXXXXXX' : '+38 (0##) ###-##-##',
    }
  },

  validations() {
    return {
      phone: { minLength: minLength(19) },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    value(val) {
      const formatted = formatPhoneNumber(val)
      if (this.phone !== formatted) {
        this.phone = formatted
      }
    },
  },

  computed: {
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty || !this.validate) {
        this.$emit('validation', true)
      } else {
        const error = !this.$v.phone.minLength
        error && errors.push('Введіть коректний номер')
        this.$emit('validation', !error)
      }
      return errors
    },

    placeholder() {
      if (!this.disabled) {
        return this.withNickname
          ? 'Почніть з "@" для вводу ніку, або "+" чи "0-9" для телефона'
          : 'Почніть з "+" або "0-9" для вводу'
      } else return null
    },
  },

  methods: {
    initialize() {
      if (this.value) {
        this.phone = formatPhoneNumber(this.value)
      }
      this.$v.phone.$touch()
    },

    blur() {
      this.validate && this.$v.phone.$touch()
    },

    input(val) {
      if (this.withNickname && val === '@') {
        this.mask = '@XXXXXXXXXXXXXXXXXXX'
      } else {
        if (val[0] !== '@') this.mask = '+38 (0##) ###-##-##'
        this.validate && this.$v.phone.$touch()
        this.$emit('change', val.replace(/[\s()-]/g, ''))
      }
    },
  },
}
</script>
