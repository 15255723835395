export function formatPhoneNumber(s) {
  if (s[0] === '@') {
    return s
  }
  if (s.length === 1 && s.length < 6) return `+38 (0${s}`
  const toInsert = [
    { position: 3, text: ' (' },
    { position: 6, text: ') ' },
    { position: 9, text: '-' },
    { position: 11, text: '-' },
  ]
  let dest = ''
  let pos = 0
  toInsert.forEach((item) => {
    if (s.length >= item.position) {
      dest += s.substring(pos, item.position)
      dest += item.text
      pos = item.position
    }
  })
  dest += s.substring(pos, s.length)
  return dest
}
